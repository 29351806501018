import { AboutStyled } from './styles/About.styled'
import { BreakLine, Title } from './styles/GlobalStyles'

function About() {
  return (
    <AboutStyled>
      <BreakLine />
      <Title id='about'>About Me</Title>
      <p>
        I am a lifelong learner and i love reading articles on topics which are
        usually avoided by us humans. In my spare time, I enjoy watching 3D
        Animation Movies, playing video-games, and browsing twitter.
      </p>
    </AboutStyled>
  )
}

export default About
