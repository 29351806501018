import styled from 'styled-components'

export const StyledHero = styled.section`
  padding: 2rem 4rem;
  display: grid;
  overflow: hidden;
  grid-template-columns: 52% 48%;
  @media ${({ theme }) => theme.breakpoints.md} {
    display: flex;
    padding: 2rem 0;
  }
`

export const HeroArticle = styled.article`
  z-index: 1;
  max-width: 1040px;
  h2 {
    width: max-content;
    font-size: 65px;
    padding: 58px 0 26px;
  }
  p {
    font-size: 24px;
    max-width: 800px;
    padding-bottom: 24px;
  }
  @media ${({ theme }) => theme.breakpoints.md} {
    h2 {
      font-size: 56px;
    }
    p {
      max-width: 670px;
      font-size: 20px;
    }
  }
  @media ${({ theme }) => theme.breakpoints.sm} {
    h2 {
      font-size: 28px;
      padding: 26px 0;
    }
    p {
      font-size: 16px;
    }
  }
`
