import styled from 'styled-components'

export const StyledTechnologies = styled.section`
  p {
    font-size: 24px;
    max-width: 70%;
    padding-bottom: 36px;
  }
  ul {
    display: flex;
    gap: 4rem;
    margin-bottom: 30px;
  }
  li {
    max-width: 320px;
    display: flex;
    flex-direction: column;
  }
  div {
    max-width: 80%;
  }
  h4 {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 8px;
  }
  .skills {
    font-size: 18px;
    max-width: 100%;
    color: rgba(255, 255, 255, 0.75);
  }
  @media ${({ theme }) => theme.breakpoints.md} {
    p {
      font-size: 20px;
      max-width: 90%;
    }
    li {
      max-width: 40%;
    }
    h4 {
      font-size: 24px;
    }
    .skills {
      font-size: 16px;
    }
  }
  @media ${({ theme }) => theme.breakpoints.sm} {
    p {
      font-size: 16px;
      max-width: 100%;
    }
    ul {
      flex-direction: column;
      gap: 0;
      margin-bottom: 0;
    }
    li {
      max-width: 100%;
      flex-direction: row;
    }
    div {
      margin-left: 1rem;
    }
    h4 {
      font-size: 20px;
    }
    .skills {
      font-size: 14px;
    }
  }
`
