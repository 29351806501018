export const projectsData = [
  {
    id: 1,
    title: 'Medium Clone',
    description:
      "This is a clone of popular online publishing platform Medium. I've cloned the main page with some dummy posts. You can navigate to single posts and add/view comments too.",
    image:
      'https://raw.githubusercontent.com/babarkhuroo/medium-clone/main/public/thumbnail.png',
    tags: ['Nextjs', 'TypeScript', 'Tailwind CSS', 'Sanity CMS'],
    code: 'https://github.com/babarkhuroo/medium-clone',
    visit: 'https://medium-clone-sheikhcamel.vercel.app/',
  },
  {
    id: 2,
    title: 'Block Master',
    description:
      'Block Master is an online database of information related to movies. You can browse for popular, most rated, trending and upcoming movies. You can also search for movies.',
    image:
      'https://raw.githubusercontent.com/babarkhuroo/Block-Master/main/src/assets/imgs/thumbnail.webp',
    tags: ['React', 'React Router', 'TMDB API', 'JavaScript'],
    code: 'https://github.com/babarkhuroo/Block-Master',
    visit: 'https://block-master-sheikhcamel.vercel.app/',
  },
  {
    id: 3,
    title: 'Comfy Sloth',
    description:
      'Comfy Sloth is a fictional e-commerce website for furniture and home decor items. You can browse for products, filter products according to your preferences and add them to cart for checkout.',
    image:
      'https://raw.githubusercontent.com/babarkhuroo/comfy-sloth-ecommerce/main/src/assets/banner.png',
    tags: ['React', 'React Router', 'Auth0', 'Stripe'],
    code: 'https://github.com/babarkhuroo/comfy-sloth-ecommerce',
    visit: 'https://comfy-sloth-sheikhcamel.netlify.app/',
  },
  {
    id: 4,
    title: 'Search Github Users',
    description:
      "Search Github Users is a simple website for checking the github user details in detail. You can view user's followers list, top repos, most used languages and most forked repos.",
    image:
      'https://raw.githubusercontent.com/babarkhuroo/github-users-tutorial/main/src/images/banner.png',
    tags: ['React', 'React Router', 'Auth0', 'Github API'],
    code: 'https://github.com/babarkhuroo/github-users-tutorial',
    visit: 'https://github-users-sheikhcamel.vercel.app/',
  },
]
