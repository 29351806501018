import { socialLinks } from '../data/NavData'
import { StyledFooter } from './styles/Footer.styled'

function Footer() {
  return (
    <StyledFooter>
      <section>
        <h4>Email</h4>
        <a href='mailto:hello@babarkhuroo.com'>hello@babarkhuroo.com</a>
        <div className='btn-container'>
          <button
            onClick={() =>
              (window.location =
                'https://1drv.ms/b/s!AoIep57iyhp84wbit5kOxKe3GPOp?e=cxxIt2')
            }>
            View Resume
          </button>
          <ul className='social-links'>
            {socialLinks.map(({ url, icon, id }) => {
              return (
                <li key={id}>
                  <a href={url}>{icon}</a>
                </li>
              )
            })}
          </ul>
        </div>
      </section>
    </StyledFooter>
  )
}

export default Footer
