import { DiReact, DiFirebase } from 'react-icons/di'
import { BreakLine, Title } from './styles/GlobalStyles'
import { StyledTechnologies } from './styles/Technologies.styled'

function Technologies() {
  return (
    <StyledTechnologies>
      <BreakLine />
      <Title id='technologies'>Technologies</Title>
      <p>
        I've worked with a range of technologies in the web development world.
      </p>
      <ul>
        <li>
          <DiReact size='3rem' />
          <div>
            <h4>Frontend</h4>
            <p className='skills'>
              Experience with
              <br />
              HTML, CSS, Tailwind CSS, Bootstrap, Material UI,
              styled-components, Bulma, JavaScript, React, Nextjs and Redux
            </p>
          </div>
        </li>
        <li>
          <DiFirebase size='3rem' />
          <div>
            <h4>Backend</h4>
            <p className='skills'>
              Experience with
              <br />
              Node.js, Express, Sanity CMS, MongoDB and Mongoose
            </p>
          </div>
        </li>
      </ul>
    </StyledTechnologies>
  )
}

export default Technologies
