import styled from 'styled-components'

export const AboutStyled = styled.section`
  p {
    font-size: 24px;
    max-width: 800px;
    color: #fafafa;
  }
  @media ${({ theme }) => theme.breakpoints.md} {
    max-width: 90%;
    p {
      font-size: 20px;
    }
  }
  @media ${({ theme }) => theme.breakpoints.sm} {
    max-width: 100%;
    p {
      font-size: 16px;
    }
  }
`
