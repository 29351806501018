import { FaGithub, FaLinkedin } from 'react-icons/fa'

export const navLinks = [
  {
    id: 1,
    text: 'Projects',
    link: 'projects',
  },
  {
    id: 2,
    text: 'Technologies',
    link: 'technologies',
  },
  {
    id: 3,
    text: 'About',
    link: 'about',
  },
]

export const socialLinks = [
  {
    id: 1,
    url: 'https://github.com/babarkhuroo',
    icon: <FaGithub size='3rem' />,
  },
  {
    id: 2,
    url: 'https://www.linkedin.com/in/babarkhuroo/',
    icon: <FaLinkedin size='3rem' />,
  },
]
