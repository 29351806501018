import BackgroundAnimation from './BackgroundAnimation'
import { StyledHero, HeroArticle } from './styles/Hero.styled'

function Hero() {
  return (
    <StyledHero>
      <HeroArticle>
        <h2>Hi! I'm Babar.</h2>
        <p>
          I am a CompSci grad, Full-stack Web Developer, kinda Gamer, and a
          Human Being.
        </p>
      </HeroArticle>
      <BackgroundAnimation />
    </StyledHero>
  )
}

export default Hero
