import styled, { css } from 'styled-components'

export const BreakLine = styled.hr`
  margin: 0px;
  width: 64px;
  height: 6px;
  border: none;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.accent1};

  ${(props) =>
    props.$margin &&
    css`
      margin: 20px auto;
    `}

  ${(props) =>
    props.$width &&
    css`
      width: 50px;
    `}

  ${(props) =>
    props.$height &&
    css`
      height: 3px;
    `}

  ${(props) =>
    props.$border &&
    css`
      border-radius: 0px;
    `}

  @media ${({ theme }) => theme.breakpoints.md} {
    width: 48px;
    height: 4px;
  }
  @media ${({ theme }) => theme.breakpoints.sm} {
    width: 32px;
    height: 2px;
  }
`

export const Title = styled.h3`
  font-size: 65px;
  margin-bottom: 16px;
  @media ${({ theme }) => theme.breakpoints.md} {
    font-size: 56px;
  }
  @media ${({ theme }) => theme.breakpoints.sm} {
    font-size: 28px;
  }
`
